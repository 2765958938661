import React, { useState } from 'react';

import { Text } from 'components';

import SocialRow from 'components/SocialRow';
import styled from 'styled-components';

import { tablet, useQuery } from 'styles/breakpoints';
import { config } from 'config';
import Layout from 'layouts/Layout';

import Person1 from '../../assets/images/teamMembers/Kazys.png';
import Person2 from '../../assets/images/teamMembers/Jacob.png';
import Person3 from '../../assets/images/teamMembers/Thomas.png';

import LinkedIn from '../../assets/icons/linkedin.svg';
import SMS from '../../assets/icons/sms.svg';
import Location from '../../assets/icons/location.svg';
import Home from '../../assets/icons/home.svg';
import Call from '../../assets/icons/call.svg';
import Social from '../../assets/icons/Socials.png';

const TitleContainer = styled.div`
  padding: 3.813rem 1rem 2rem 1rem;
  display: flex;
  justify-content: center;
  @media ${tablet} {
    padding: 1.5rem 1rem;
  }
`;

const StyledTitle = styled(Text)`
  width: 100%;
  strong {
    font-weight: 600;
  }
`;

const TeamSection = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;
const SubTitle = styled(Text)`
  font-size: 1.125rem;
  margin-bottom: 4.5rem;
  @media ${tablet} {
    font-size: 1rem;
    margin-bottom: 2rem;
  }
`;

const TeamWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media ${tablet} {
    flex-direction: column;
  }
`;

const ImageNameWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 38px 40px;
`;

const Name = styled(Text)`
  font-size: 1.25rem;
  font-weight: 600;
`;

const Role = styled(Text)``;

const Logo = styled.img`
  height: 192px;
  width: 192px;
  border-radius: 50%;
  margin: 0 auto 39px;
  @media ${tablet} {
    height: 240px;
    width: 240px;
  }
`;

const ContactSection = styled.div`
  display: flex;
  margin: 0 1rem;
  margin: auto;
  max-width: 100%;
  padding: 0 1rem;
  width: 1204px;
  justify-content: space-between;
  @media ${tablet} {
    flex-direction: column;
  }
`;

const LeftSide = styled.div``;

const LeftSideTitle = styled(Text)`
  font-size: 1.75rem;
  font-weight: 600;
  margin-bottom: 1.875rem;
  @media ${tablet} {
    font-size: 1.5rem;
  }
`;

const LinkedInLogo = styled(LinkedIn)`
  height: 32px;
  width: 32px;
  margin-right: 0.5rem;
`;

const CallLogo = styled(Call)`
  height: 32px;
  width: 32px;
  margin-right: 1.5rem;
`;

const HomeLogo = styled(Home)`
  height: 32px;
  width: 32px;
  margin-right: 1.5rem;
`;

const LocationLogo = styled(Location)`
  height: 32px;
  width: 32px;
  margin-right: 1.5rem;
`;

const SmsLogo = styled(SMS)`
  height: 32px;
  width: 32px;
  margin-right: 1.5rem;
`;

const NameWrap = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
`;

const ItemWrap = styled.div`
  display: flex;
  padding-bottom: 2rem;
`;

const TextWrap = styled.div``;

const Title = styled(Text)`
  font-size: 1.125rem;
  font-weight: 600;
`;

const LeftSubTitle = styled(Text)`
  padding-top: 0.688rem;
`;

const RightSide = styled.div``;

const RightSideTitle = styled(Text)`
  font-size: 1.75rem;
  font-weight: 600;
  margin-bottom: 1.875rem;
  @media ${tablet} {
    font-size: 1.5rem;
  }
`;

const CardSection = styled.div`
  margin: auto;
  max-width: 100%;
  padding: 0 32px;
  width: 1204px;
  padding: 4.5rem 0;
  @media ${tablet} {
    padding: 28px 0 0;
  }
`;

const CardSectionWrap = styled.div`
  display: flex;
  justify-content: center;
  @media ${tablet} {
    flex-direction: column;
  }
`;

const RightCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 45.8%;

  @media ${tablet} {
    width: 100%;
  }
`;

const RightCard = styled.div`
  background: #fcf7f1;
  border-radius: 16px;
  flex-grow: 1;
  overflow: hidden;
  padding: 48px 48px 176px;
  position: relative;
  @media ${tablet} {
    border-radius: 0px;
    padding: 48px 16px 228px;
  }
`;

const ZoomImage = styled.img`
  bottom: 0;
  position: absolute;
  right: 0;
`;

const SocialImage = styled.img`
  position: absolute;
  bottom: -20px;
  right: 0;
  @media ${tablet} {
    bottom: 0;
  }
`;

const CardTitle = styled(Text)`
  font-size: 1.75rem;
  font-weight: 700;
  margin-bottom: 21px;
`;

const Contact = () => {
  const { isTablet } = useQuery();

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    honeypot: '',
  });
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const updateInput = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = event => {
    event.preventDefault();

    if (formData.honeypot === '') {
      sendEmail();
    } else {
      setError(true);
    }
  };

  const sendEmail = async () => {
    try {
      setLoading(true);
      await fetch(config.FIREBASE_MAILING, {
        method: 'POST',
        body: JSON.stringify({
          email: formData.email,
          name: formData.name,
          message: formData.message,
          funnelCode: 'BW',
        }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
      setFormData({
        name: '',
        email: '',
        message: '',
        honeypot: '',
      });
      setError(false);
      setSuccess(true);
    } catch {
      setSuccess(false);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const renderBottomComponent = () => {
    if (success) {
      return <SuccessText>Message was sent successfully!</SuccessText>;
    }
    if (error) {
      return (
        <>
          <ErrorText>There was an issue sending your email.</ErrorText>
          <StyledButton type="submit">Submit</StyledButton>
        </>
      );
    }
    return (
      <StyledButton disabled={loading ? true : false} type="submit">
        Submit
      </StyledButton>
    );
  };

  return (
    <Layout isMobileLink>
      <TitleContainer>
        <StyledTitle
          type={isTablet ? 'h2600' : 'h125Rem'}
          textAlign="center"
          fontWeight="400"
        >
          Hello, how <strong>can we help you?</strong>
        </StyledTitle>
      </TitleContainer>
      {/* <TeamSection>
        <SubTitle>Meet our team members</SubTitle>
        <TeamWrap>
          <ImageNameWrap>
            <Logo src={Person1} />
            <NameWrap>
              <LinkedInLogo />
              <Name>Kazimieras Melaika</Name>
            </NameWrap>
            <Role>Medical</Role>
          </ImageNameWrap>

          <ImageNameWrap>
            <Logo src={Person2} />
            <NameWrap>
              <LinkedInLogo />
              <Name>Jokūbas Lapė</Name>
            </NameWrap>
            <Role>Product</Role>
          </ImageNameWrap>

          <ImageNameWrap>
            <Logo src={Person3} />
            <NameWrap>
              <LinkedInLogo />
              <Name>Tomas Butkevičius</Name>
            </NameWrap>
            <Role>Marketing</Role>
          </ImageNameWrap>
        </TeamWrap>
      </TeamSection> */}

      <ContactSection>
        <LeftSide>
          <LeftSideTitle>Get in touch with us</LeftSideTitle>
          <ItemWrap>
            <HomeLogo />
            <TextWrap>
              <Title>Medical Score</Title>
              <LeftSubTitle>Our Company</LeftSubTitle>
            </TextWrap>
          </ItemWrap>

          <ItemWrap>
            <CallLogo />
            <TextWrap>
              <Title>+1 415 882 6760</Title>
              <LeftSubTitle>Phone number</LeftSubTitle>
            </TextWrap>
          </ItemWrap>

          <ItemWrap>
            <SmsLogo />
            <TextWrap>
              <Title>help@brainway.app</Title>
              <LeftSubTitle>Email address</LeftSubTitle>
            </TextWrap>
          </ItemWrap>

          <ItemWrap>
            <LocationLogo />
            <TextWrap>
              <Title>100 Pine Street, Suite 1250</Title>
              <LeftSubTitle>San Francisco, CA 94111</LeftSubTitle>
            </TextWrap>
          </ItemWrap>
        </LeftSide>
        <RightSide>
          <RightSideTitle>Leave us a message</RightSideTitle>
          <form onSubmit={handleSubmit}>
            <StyledLabel>
              Email <StyledStar>*</StyledStar>
            </StyledLabel>
            <HoneyPot
              name="honeyPot"
              onChange={updateInput}
              value={formData.honeyPot || ''}
            />
            <StyledInput
              type="email"
              name="email"
              required={true}
              onChange={updateInput}
              value={formData.email || ''}
            />

            <StyledLabel>
              Subject <StyledStar>*</StyledStar>
            </StyledLabel>
            <StyledInput
              type="text"
              name="name"
              required={true}
              onChange={updateInput}
              value={formData.name || ''}
            />

            <StyledLabel>
              Description <StyledStar>*</StyledStar>
            </StyledLabel>
            <StyledTextArea
              name="message"
              onChange={updateInput}
              value={formData.message || ''}
            />

            <Note>
              Please enter the details of your request. A member of our team
              will respond as soon as possible.
            </Note>
            {renderBottomComponent()}
          </form>
        </RightSide>
      </ContactSection>

      <CardSection>
        <CardSectionWrap>
          <RightCardContainer>
            <RightCard>
              <SocialImage src={Social} />
              <CardTitle>Join our community</CardTitle>
              <StyledText>
                Read inspiring success stories, get useful advices, share your
                own thoughts and celebrate achievements by joining the Brainway
                community on both Facebook and reddit!
              </StyledText>
              <SocialRow />
            </RightCard>
          </RightCardContainer>
        </CardSectionWrap>
      </CardSection>
    </Layout>
  );
};

export default Contact;

const HoneyPot = styled.input`
  display: none;
`;

const StyledStar = styled.span`
  color: #e53535;
`;

const SuccessText = styled(Text)`
  padding-bottom: 12px;
  font-size: 0.9rem;
  color: rgb(6, 194, 112);
`;

const ErrorText = styled(Text)`
  padding-bottom: 12px;
  font-size: 0.9rem;
  color: rrgb(255, 59, 59);
`;

const Note = styled.div`
  color: #1c1c28;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: -0.024em;
  line-height: 140%;
  margin: -20px 0 47px;
  opacity: 0.8;
`;

const StyledButton = styled.button<{ disabled?: boolean }>`
  align-items: center;
  background: ${({ disabled }) => (disabled ? '#EBEBF0' : '#1c1c28')};
  border: 0;
  color: #fff;
  display: inline-flex;
  justify-content: center;
  letter-spacing: -0.024em;
  min-height: 48px;
  min-width: 240px;
  padding: 14px 16px;
  text-align: center;
  transition: all 0.3s ease;
  border-radius: 8px;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 140%;
  cursor: ${({ disabled }) => (disabled ? 'none' : 'pointer')};
  @media ${tablet} {
    width: 100%;
  }
`;

const StyledLabel = styled.label`
  display: block;
  margin-bottom: 12px;
  color: #1c1c28;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.024em;
  line-height: 140%;
`;

const StyledInput = styled.input`
  border-radius: 8px;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 140%;
  background: #fff;
  border: 1px solid #e4e4eb;
  color: #1c1c28;
  display: block;
  height: 48px;
  margin: 0 0 32px;
  outline: none;
  padding: 8px 16px;
  width: 100%;
  -webkit-appearance: none;
`;

const StyledTextArea = styled.textarea`
  border-radius: 8px;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 140%;
  background: #fff;
  border: 1px solid #e4e4eb;
  color: #1c1c28;
  display: block;
  height: 48px;
  margin: 0 0 32px;
  outline: none;
  padding: 8px 16px;
  width: 100%;
  min-height: 144px;
`;

const StyledText = styled(Text)`
  margin-bottom: 2rem;
`;
